import React from "react";
import '../css/Home.css';
function Home() {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="text-center home-h1">Welcome to OXEON PLANETS</h1>
                    <p style={{textAlign: "justify"}} className="home-para">
                    Oxeon Planets is your premier partner for comprehensive solutions in web development, CCTV security, biometrics, and automation. We provide tailored, high-quality services designed to meet the unique requirements of your business, ensuring seamless integration and maximum impact. Our dedicated team of skilled professionals delivers innovative, reliable, and cost-effective results that prioritize security, efficiency, and functionality. With a strong reputation built on excellence and a commitment to exceeding client expectations, Oxeon Planets empowers businesses to thrive in today’s fast-paced, digital, and interconnected world.
                    </p>
                </div>
            </div>
            <div />
        </div>
    );
}

export default Home;
